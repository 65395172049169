html, body {
	height: auto !important;
	min-height: 0 !important;
	max-height: none !important;
}

.l-header {
	display: none;
}

img.background {
	display: none;
}

.page .l-main {
	padding: 0;
}

.page h1#page-title {
	padding: 0;
}

.dkjs-icon-as-download, .dkjs-icon-as-left-arrow-small {
	display: none;
}

.node-einfache-seite .field-seite-untertitel {
	padding: 0;
}

.page .l-main .main .inner-main {
	padding: 10px;
}

.block-easy-breadcrumb {
	display: none;
}

.socialShare {
	display: none;
}

.l-footer {
	display: none;
}

ul.button-group, .leistungen {
	display: none;
}

.node.view-mode-full:before {
	display: none;
}

.paragraphs-items:before {
	display: none;
}

.node-einfache-seite .paragraphs-items .field-seite-inhaltselement:first-child .paragraphs-item-textblock.blue:after, .node-veranstaltung.view-mode-full .group-infos:after, .node-aktuelles.view-mode-full .paragraphs-items .field-aktuelles-inhalt:first-child .paragraphs-item-textblock.blue:after {
	display: none;
}

.field-seite-bild {
	display: none;
}

.field-aktuelles-headerbild {
	display: none;
}

.field-va-headerbild {
	display: none;
}

body.node-type-einfache-seite .page #page-title {
	margin: 0;
}